.favicon-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px; /* Adjust margin-bottom as desired */
  }
  a:link {
    color: black; /* Set the color for unvisited links */
  }
  
  a:visited {
    color: black; /* Set the color for visited links */
  }
  
  a:hover {
    color: #D2F4D7; /* Set the color for links on hover */
  }
  
  a:active {
    color: black; /* Set the color for links when clicked */
  }
  
