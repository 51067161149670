html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  body {
    background-color: #236476;
  }
  

  .text{
    font-family: Menlo, monospace;
    color: #D2F4D7;
  }
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; 
  }
  
  .circular-image {
    width: 150px;
    height: 150px; 
    border-radius: 50%;
    object-fit: cover;
  }
  

  .app {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100vh;
  }
  
  .center-container {
    text-align: center;
    margin-bottom: 25vh; /* Adjust the margin as desired */
  }
  
  .navbar {
    background: none;
    list-style: none;
    padding: 0;
    position: absolute;
    margin: 0;
    font-family: Menlo, monospace;

  }
  
  .navbar ul li {
    display: inline;
    margin-right: 30px;
  }
  
  .navbar ul li a {
    text-decoration: none;
    color: #ffffff;
  }
  
  .active {
    position: relative;
    color: #D2F4D7;

  }
  
  .active::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 10px); /* Adjust as needed */
    height: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    opacity: 0.5;
    z-index: -1; /* Ensure the highlight is behind the text */
  }