.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
}

.project {
    border: 1px solid red;
    padding: 20px;
    text-align: center;
    background-color: red; /* Change this color to your preferred shade of gray */
    border-radius: 10px; /* Adjust this value to control the roundness of the corners */
}

