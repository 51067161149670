.type {
    font-family: Menlo, monospace;
    color: #D2F4D7;
  }
  
  .cursor {
    font-weight: bold;
    position: relative;
    animation: blink 0.8s infinite;
  }
  

  .Type-container {
    cursor: none;
  }
  
  /* Add this if you want to have a hover effect */
  