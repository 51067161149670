.container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Menlo, monospace;  }
.header{
    color: #D2F4D7;

}
.links{
    font-family: Menlo, monospace;  
    color: #bebebe

}